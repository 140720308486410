.image-container {
    justify-content: center;
    align-items: center;
    height: 100vh; /* This will make the container take the full height of the screen */
    box-sizing: border-box;
    overflow: auto; /* This will enable scrolling if the image is larger than the screen */
}

.bcard {
    width: calc( 98% ); /* This will ensure the image doesn't overflow the container */
    max-width: 500px;
    object-fit: contain; /* This maintains the image aspect ratio */
    border-radius: 15px; /* Adjust as needed for desired roundness */
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); /* This gives a floating effect */
    margin: auto;
    display: block;
    transition: transform 0.3s ease-in-out; /* This adds a smooth transition effect when transforming the image */
}

.bcard:hover {
    transform: scale(1.02); /* This makes the image slightly larger when hovered */
    cursor: pointer; /* This changes the cursor to a hand when hovering over the image */
}

.caption {
    margin-top: 10px;
    text-align: center;
    color: #333;
    font-size: 16px;
}


.bcard-animate{
    display: block;
    width: 100%;
    margin: auto;
}

.bcard-animate-enter {
    transform: scale(0);
    display: block;
    width: 100%;
    margin: auto;
}

.bcard-animate-enter-active {
    transform: scale(1);
    transition: transform 500ms;
    display: block;
    width: 100%;
    margin: auto;
}

.bcard-animate-exit {
    transform: scale(1);
    display: block;
    width: 100%;
    margin: auto;
}

.bcard-animate-exit-active {
    transform: scale(0);
    transition: transform 500ms;
    display: block;
    width: 100%;
    margin: auto;
}

.bcard-animate-enter-done{
    display: block;
    width: 100%;
    margin: auto;
}